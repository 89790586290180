<template>
  <div>
    <div class="pay">
      <div class="pay-header">
        <span>
          充值账号：
          <em>{{account}}</em>
        </span>
        <span>
          余额：
          <em>{{balance}}</em>
        </span>
        <a class="fr" @click="linkCardStockPay">
          前往支付充值
          <i class="iconfont iconyoujiantou"></i>
        </a>
      </div>
      <el-row class="container1">
        <el-col :span="9">
          <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="ruleForm">
            <el-form-item class="item">
              <p>
                <i class="iconfont iconzhanghao"></i>卡劵卡号
              </p>
              <el-input v-model="ruleForm.cardKey" placeholder="卡号复制到这里哦！"></el-input>
            </el-form-item>
            <el-form-item class="item">
              <p>
                <i class="iconfont iconmima"></i>验证码
              </p>
              <el-input v-model="ruleForm.imageCode" placeholder="验证码输入到这里！"></el-input>
              <div class="codeImg" @click="refreshCode">
                <s-identify :identifyCode="identifyCode"></s-identify>
              </div>
            </el-form-item>
            <el-button type="primary" @click="goPay('ruleForm')">点击充值</el-button>
          </el-form>
        </el-col>
        <el-col :span="13" :offset="2">
          <ul class="list">
            <li class="item" v-for="(item,index) in cardList" :key="index" @click="goCard(item.url)">
              <img :src="item.img" alt />
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <el-row class="container2">
      <h2 class="title">关于卡劵</h2>
      <p>
        当您获得卡号后请在下方输入卡号点击充值即可，余额使用完后您可以继续购买卡券充值，
        <em>卡券一经充值恕不退款</em>，望见谅！
      </p>
      <p>
        卡券金额可以叠加使用，多个卡券进行充值，系统将自动为您
        <em>更新余额</em>。
      </p>
      <p>为避免被恶意下单，退款等等欺诈行为（比如差评师各种），所有申请退款的，均被自动永久加入豪华午餐（小黑屋），永久不得下单购买，并且加入自动发货软件数十万黑名单库中。</p>
    </el-row>
    <el-row class="container3">
      <el-table :data="tableData">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column label="充值卡号" prop="paySerial"></el-table-column>
        <el-table-column label="充值方式" :formatter="payFormat" ></el-table-column>
        <el-table-column label="卡劵金额" prop="payAmount"></el-table-column>
        <el-table-column label="充值时间" prop="payTime"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalSize"
        v-if="totalSize > 10"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { doCharge } from "../../api/charge";
  import { getChargeList } from "../../api/order";
  import { message } from '@/utils/resetMessage';
  import SIdentify from '../../components/identify/identify';
  import { getSessionUser } from '../../api/auth';
export default {
  components:{
    SIdentify
  },
  data() {
    return {
      ruleForm: {
        cardKey: "",
        imageCode: ""
      },
      isLoggedIn:false,
      account: "",
      balance: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalSize:0,
      cardList: [],
      rules: {
        cardKey: [{ required: true, message: "请输入卡劵卡号", trigger: "blur" }],
        imageCode: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
      identifyCodes: "1234567890",
      identifyCode: ""
    };
  },
  mounted() {
    this.isLoggedIn = (this.$store.state.user.token !== null || this.$store.state.user.token !== "")
      && (this.$store.state.user.loginNameType!=null )? true : false;
    this.getUserList();
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    this.getCardList();
    this.getTableData();
  },
  methods: {
    payFormat(row){
      if (row.payWay === 1) {
        return '卡券充值';
      }else if(row.payWay === 3){
        return '微信充值';
      }else{
        return '支付宝充值';
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    getUserList(){
      getSessionUser({}).then((result) => {
        this.$store.dispatch("setUserInfo", result);
        if (this.$store.state.user.loginNameType) {
          this.account = this.$store.state.user.loginNameType == 1
                  ? this.$store.state.common.userInfo.mobile
                  : this.$store.state.common.userInfo.mail;
          this.balance = this.$store.state.common.userInfo.blance + "元";
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
    getCardList() {
      this.cardList = [
        {
          img: '',
          url:''
        },
        {
          img: '',
          url:''
        },
        {
          img: '',
          url:''
        }
      ];
    },
    getTableData() {
      let dataform = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        type:1
      };
      //获取充值历史列表
      getChargeList(dataform).then(result => {
        // 返回到上一个页面
        console.log(JSON.stringify(result));
        this.tableData = result.list;
        this.totalSize = result.count;
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    //充值界面
    goPay(formName) {
      if(this.isLoggedIn){
        this.$refs[formName].validate(valid => {
          if (valid) {
            if(this.ruleForm.imageCode == this.identifyCode){
              //进行充值
              doCharge(this.ruleForm).then(result => {
                // 返回到上一个页面
                message.success({
                  message: '充值成功',
                  duration: 2000,
                  offset: 80
                })
                this.$store.dispatch("setUserInfo", result);
                this.ruleForm = {};
                this.getUserList();
                this.getTableData();
              }).catch(error => {
                message.error({
                  message: '充值异常，请重试',
                  duration: 2000,
                  offset: 80
                })
                this.refreshCode();
              });
            }else{
              message.error({
                message: '输入的验证码不正确，请重新输入',
                duration: 2000,
                offset: 80
              })
              this.refreshCode();
            }
          } else {
            return false;
          }
        });
      }else{
        message.error({
          message: '您还没登录用户，请登录',
          duration: 2000,
          offset: 80
        })
      }
    },
    goCard(url) {
      window.open(url, "_blank");
    },
    linkCardStockPay() {
      this.$router.push({ path: "/pay" });
      this.$store.commit("asideMenuId", 2);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      console.log("jjj"+val);
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pay {
  padding: 25px 40px 40px;
  margin-bottom: 20px;
  background: #fff;
  .pay-header {
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
    span {
      color: #767676;
      em {
        color: #000;
        font-size: 16px;
      }
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
    .fr {
      float: right;
      font-size: 14px;
      color: #909090;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.container1 {
  .item {
    margin-bottom: 20px;
    position: relative;
    p {
      display: block;
      font-size: 14px;
      color: #000;
      .iconfont {
        margin-right: 10px;
        font-size: 16px;
      }
    }
    .input {
      width: 100%;
      height: 40px;
      border: none;
      border-bottom: 1px solid #767676;
    }

    .inputCode {
      width: 80%;
    }
    .codeImg {
      position: absolute;
      right: 0;
      bottom: 15px;
      width: 160px;
      height: 50px;
      border: 1px solid #e5e5e5;
    }
  }
  .el-button {
    margin-top: 40px !important;
    padding: 12px 25px;
    height: 44px !important;
  }
  .list {
    li {
      margin-bottom: 10px;
      float: left;
      width: 240px;
      cursor: pointer;
      img {
        width: 90%;
      }
    }
  }
}
.container2 {
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #feaf06;
  padding: 5px 35px 15px;
  .title {
    margin-bottom: 16px;
    line-height: 50px;
    font-size: 16px;
    color: #1f1f1f;
    border-bottom: 1px solid #e8e8e8;
  }
  p {
    margin-bottom: 15px;
    line-height: 20px;
    color: #898989;
  }
}
.container3 {
  margin-bottom: 60px;
}
</style>